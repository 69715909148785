import PageContainer from "../layout/PageContainer";

export default function NotFound() {
  return (
    <PageContainer>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-1/2">
        <label className="text-9xl">404</label>
        <label className="text-5xl">Not Found!</label>
      </div>
    </PageContainer>
  );
}
