// FaviconManager.tsx
import React, { useEffect } from "react";

const FaviconManager: React.FC = () => {
  useEffect(() => {
    const updateFavicon = () => {
      let faviconUrl = "/favicons/green-logo.ico";

      if (window.location.host) {
        if (
          window.location.host.includes("cms.gold") ||
          window.location.host.includes("cms.pb") ||
          window.location.host.includes("cms.partnerbank")
        ) {
          faviconUrl = "/favicons/favicon.ico";
        } else if (window.location.host.includes("cms.ffe") || window.location.host.includes("cms.fundforeducation")) {
          faviconUrl = "/favicons/ffe-favicon.ico";
        } else {
          faviconUrl = "/favicons/green-logo.ico";
        }
      }

      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = faviconUrl;
    };

    // Update favicon on initial load
    updateFavicon();

    // Listen for URL changes
    window.addEventListener("popstate", updateFavicon);
    window.addEventListener("pushstate", updateFavicon);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("popstate", updateFavicon);
      window.removeEventListener("pushstate", updateFavicon);
    };
  }, []);

  return null; // This component does not render anything visible
};

export default FaviconManager;
