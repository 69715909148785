import React, { useEffect, useState } from "react";
import Dropdown from "../../inputs/Dropdown";
import { StatusBoxType } from "../../../types/types";
import { OptionType } from "../../../utils/types";
import Button, { ButtonType } from "../../Button";
import DateInput from "../../inputs/DateInput";
import MultiCheckBoxLanguages, { Option } from "../../inputs/multiCheckBoxLanguage/MultiCheckBoxLanguages";
import Checkbox from "../../inputs/Checkbox";
import SearchInput from "../../inputs/SearchInput";
import SimpleDropDown from "../../inputs/SimpleDropDown";

export type ApplyFilterType = {
  status: OptionType | null;
  type: OptionType | null;
  timeForm: Date;
  timeTo: Date;
  selectedLanguages: string[];
};
const FilterBox: React.FC<{
  applyButtonHandler: (input: ApplyFilterType) => void;
  languages: Option[];
  loading: boolean;
  searchHandler: (input: string) => void;
}> = ({ applyButtonHandler, languages, loading, searchHandler }) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [typeOption, setTypeOption] = useState<OptionType | null>(null);
  const [resetIsEnable, setResetIsEnable] = useState(true);
  const [timeFrom, setTimeFrom] = useState<Date>(new Date(0));
  const [timeTo, setTimeTo] = useState(new Date(8640000000000000));
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);

  const options: OptionType[] = [
    { label: "All", key: StatusBoxType.AL.toString() },
    { label: "Draft", key: StatusBoxType.DF.toString() },
    { label: "Published", key: StatusBoxType.PB.toString() },
    { label: "ِDrafted Published", key: StatusBoxType.DP.toString() },
  ];

  const maxDate = new Date(8640000000000000);
  const minDate = new Date(0);
  const resetHandler = () => {
    setResetLoading(true);
    setTimeFrom(minDate);
    setTimeTo(maxDate);
    setResetIsEnable(!resetIsEnable);
    setSelectedOption(null);
    setTypeOption(null);
    setSelectedLanguages([]);
    applyButtonHandler({
      status: { label: "All", key: StatusBoxType.AL.toString() },
      timeForm: minDate,
      timeTo: maxDate,
      type: { key: StatusBoxType.AL.toString(), label: "All" },
      selectedLanguages: [],
    });
  };

  useEffect(() => {
    resetLoading && !loading && setResetLoading(false);
  }, [loading, resetLoading]);

  useEffect(() => {
    applyLoading && !loading && setApplyLoading(false);
  }, [loading, applyLoading]);

  const languageSelectorHandler = (input: { lang: string; status: boolean }) => {
    if (input.status) {
      !selectedLanguages.includes(input.lang) && setSelectedLanguages([...selectedLanguages, input.lang]);
    } else {
      setSelectedLanguages(selectedLanguages.filter((item) => item !== input.lang));
    }
  };
  const allLaguagesArray: string[] = [];
  languages.map((lang) => allLaguagesArray.push(lang.value));

  const allOptionHandler = () => {
    setSelectedLanguages(allLaguagesArray);
  };
  useEffect(() => {
    selectedLanguages.length === 0 && setSelectedAll(false);
  }, [selectedLanguages]);

  return (
    <div className="mx-12 my-6 bg-secondary-100 font-medium text-base py-3 shadow-sm">
      <div className="px-6">
        <SearchInput searchHandler={searchHandler} className="my-6 md:w-[400px]" />
      </div>
      <div className="h-[1px] w-full bg-secondary-400" />
      <div className="960:flex flex-wrap px-6 mt-6">
        <div>
          <div className="1090:flex">
            <label className="text-base font-light w-[43px] flex justify-end">Status</label>

            <SimpleDropDown
              defaultOption="All"
              onChange={(option) => setSelectedOption(option)}
              selectedOption={selectedOption}
              options={options}
              className="w-[217px] 1090:ml-6"
            />
          </div>
          <div className="1090:flex mt-4">
            <label className="text-base font-light w-[43px] flex 1090:justify-end">Type</label>
            <SimpleDropDown
              defaultOption="All"
              onChange={(option) => setTypeOption(option)}
              options={[
                { key: StatusBoxType.AL.toString(), label: "All" },
                { key: "Page", label: "Page" },
                { key: "Popup", label: "Popup" },
                { key: "Post", label: "Post" },
              ]}
              selectedOption={typeOption}
              className="w-[217px] 1090:ml-6"
            />
          </div>
        </div>

        <div className="1325:ml-[80px] 960:ml-3 960:mt-0 mt-2">
          <div className="1090:flex">
            <div className="text-base font-light w-[92px]">Language</div>
            <div className="616:w-[450px] 1090:ml-4">
              <MultiCheckBoxLanguages
                languages={languages}
                onChange={languageSelectorHandler}
                selectedLanguages={selectedLanguages}
                className=""
              >
                <li
                  onClick={() => {
                    setSelectedAll!(!selectedAll);
                    allOptionHandler();
                  }}
                  className={`px-4 py-[14px] flex border-b  hover:bg-secondary-100
                  border-secondary-300 cursor-pointer text-[13px] font-light text-black2B`}
                >
                  <Checkbox onChange={() => {}} value={selectedAll!} name="" />
                  <span>All</span>
                </li>
              </MultiCheckBoxLanguages>
            </div>
          </div>
          <div className="1090:flex mt-4">
            <label className="text-base font-light w-[92px] flex justify-end">Last Updated</label>
            <div className="616:flex ">
              <DateInput
                resetIsEnable={resetIsEnable}
                inputValidation={() => {}}
                className="h-[43px] w-[217px] 1090:ml-4"
                placeHolder="From"
                onDateChanged={(date) => {
                  setTimeFrom(date);
                }}
              />
              <DateInput
                resetIsEnable={resetIsEnable}
                className="616:ml-4 w-[217px] h-[43px] 616:mt-0 mt-2 "
                inputValidation={() => {}}
                placeHolder="To"
                onDateChanged={(date) => {
                  setTimeTo(date);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-end items-end 960:mt-0 mt-2">
          <div>
            <Button className="px-[12px] mr-4" onClick={resetHandler} type={ButtonType.SECONDARY} showLoading={resetLoading}>
              Reset
            </Button>
          </div>
          <div className="1157:mt-0 mt-4">
            <Button
              className="px-[12px]"
              type={ButtonType.PRIMARY}
              onClick={() => {
                setApplyLoading(true);
                applyButtonHandler({
                  status: selectedOption,
                  timeForm: timeFrom,
                  timeTo: timeTo,
                  type: typeOption,
                  selectedLanguages: selectedLanguages,
                });
              }}
              showLoading={applyLoading}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;
