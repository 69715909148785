import React, { useState } from "react";

const MenuItem: React.FC<{
  titleKey: string;
  onClick: () => void;
  onBlur?: () => void;
  className?: string;
  children?: React.ReactNode;
  disable?: boolean;
  subMenu?: { title: string; onClick: () => void }[];
}> = ({ titleKey, onClick, children, className, disable, onBlur, subMenu }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <li
      onMouseOver={() => subMenu && setShowSubMenu(true)}
      onMouseOut={() => subMenu && setShowSubMenu(false)}
      className={`hover:cursor-pointer text-[13px] font-light hover:bg-Gold_300 px-4 ${className} ${disable && "text-gray-300"} relative`}
      onClick={() => {
        !subMenu && onClick();
      }}
      onBlur={onBlur}
    >
      {children}
      <p>{titleKey}</p>
      {showSubMenu && (
        <ul tabIndex={0} className="absolute bg-white rounded shadow inset-0 -translate-x-36 ">
          {subMenu?.map((menu, i) => (
            <li className="px-4 text-[13px] font-light py-1" key={i} onClick={() => menu.onClick()}>
              {menu.title}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
