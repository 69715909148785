import React, { useState } from "react";
import { Row } from "react-table";
import { PostTypeType, PostTypeOptionType, PostTypeAdditionalContent } from "./ManagePostType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import OptionMenu from "./OptionMenu";
const TableRow: React.FC<{
  row: Row<PostTypeType & PostTypeAdditionalContent>;
  onOptionClick: (dictValue: PostTypeType & PostTypeAdditionalContent, selectedValue: PostTypeOptionType, id?: number) => void;
}> = ({ row, onOptionClick }) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState<{
    dictValue?: PostTypeType & PostTypeAdditionalContent;
    showModal: boolean;
  }>({
    showModal: false,
  });
  return (
    <tr className={`px-4 text-black2B border border-secondary-200 border-t-0 border-r-0 border-l-0  border-b`}>
      <td className="text-sm font-light pl-4 td-firefox table__cell sticky left-0">{row.original.name}</td>
      {row.original.badge && <td className="text-sm font-light pl-4  td-firefox">{row.original.badge}</td>}
      <td className="text-sm font-light pl-4  td-firefox">
        <FontAwesomeIcon
          icon={row.original.isActive ? faCheck : faClose}
          className={`text-lg pl-4  ${row.original.isActive ? "text-success" : "text-error"}`}
        />
      </td>

      <td className="text-sm font-light pl-4  td-firefox">
        <FontAwesomeIcon
          icon={row.original.showSummaryInPublicHeader ? faCheck : faClose}
          className={`text-lg pl-4  ${row.original.showSummaryInPublicHeader ? "text-success" : "text-error"}`}
        />
      </td>

      <td
        className="text-sm font-light pl-4 py-[13px] flex flex-row-reverse mr-3  td-firefox"
        onClick={() =>
          setShowOptionsMenu({
            dictValue: row.original,
            showModal: !showOptionsMenu.showModal,
          })
        }
        tabIndex={0}
        onBlur={() => {
          setTimeout(() => {
            setShowOptionsMenu({ dictValue: undefined, showModal: false });
          }, 300);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} className=" text-xl text-gray cursor-pointer w-6 h-6" />
        <OptionMenu
          showOptionMenu={showOptionsMenu.showModal && row.original.id === showOptionsMenu.dictValue?.id}
          onOptionClick={(selectedItem, id) => {
            onOptionClick(row.original, selectedItem, id);
          }}
          row={row}
        />
      </td>
    </tr>
  );
};

export default TableRow;
