import { useEffect, useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { getContentList, deleteContent, duplicateContent, setAsHomePage } from "../../../api/content";
import { ContentListType } from "../../../types/types";
import Table from "./table/Table";
import Title from "./Title";
import FilterBox, { ApplyFilterType } from "./FilterBox";
import PageContainer from "../../layout/PageContainer";
import AreYouSureModal from "../../manage_content/AreYouSureModal";
import AddContentButton from "./AddContentButton";

const ContentList = () => {
  const [languages, setLanguages] = useState<{ value: string; label: string }[]>([]);
  const [contentList, setContentList] = useState<ContentListType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [warningMessage, setWarningMessage] = useState("");
  const [shownContentList, setShownContentList] = useState<ContentListType[]>(contentList);
  const [hasDeletePermission, setHasDeletePermission] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filterInput, setFilterInput] = useState<ApplyFilterType>({
    type: { key: "", label: "" },
    selectedLanguages: [],
    status: { key: "", label: "" },
    timeForm: new Date(0),
    timeTo: new Date(8640000000000000),
  });
  const [snackbarState, setSnackbarState] = useState({ message: "", showSnackbar: false, operationIsSuccess: false });
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    const dateFrom = new Date(filterInput?.timeForm).getTime();
    const dateTo = new Date(filterInput?.timeTo).getTime();

    getContentList(
      `?${`${searchInput ? `search=${searchInput}` : `search=`}`}${
        filterInput?.status?.label !== undefined && filterInput?.status?.label !== "" ? `&status=${filterInput?.status?.key}` : ""
      }${filterInput?.type?.label !== undefined && filterInput?.type?.label !== "" ? `&type=${filterInput?.type?.key}` : ""}${
        dateFrom !== 0 ? `&last_updated_from=${dateFrom}` : ""
      }${dateTo !== 8640000000000000 ? `&last_updated_to=${dateTo}` : ""}${
        filterInput.selectedLanguages.length !== 0 ? `&language=${filterInput.selectedLanguages}` : ""
      }`
    ).then((payload) => {
      if (payload.status === 200) {
        setContentList(payload.data.contents);
        setLanguages(payload.data.languages);
        setLoading(false);
      }
    });
  }, [searchInput, filterInput]);
  useEffect(() => {
    setShownContentList(contentList);
  }, [contentList, filterInput]);

  const columns = [
    { Header: "Title", accessor: "title" },
    { Header: "Language", accessor: "languages", disableSortBy: true },
    { Header: "Original Page", accessor: "originalPage" },
    { Header: "Type", accessor: "type" },
    { Header: "Last Updated", accessor: "lastUpdates" },
    { Header: "Status", accessor: "status" },
    { Header: " ", accessor: "" },
  ];

  const deleteRowHandler = (id: string) => {
    setDeleteLoading(true);
    deleteContent(id)
      .then((response) => {
        if (response.status === 204) {
          setDeleteLoading(false);
          setSnackbarState({ message: "Content Deleted Successfully", showSnackbar: true, operationIsSuccess: true });
          const selectedRow = contentList.findIndex((content) => content.id === id);
          const newContentList = [...contentList];
          newContentList.splice(selectedRow, 1);
          setContentList(newContentList);
        }
      })
      .catch(() => {
        setSnackbarState({
          message: "Content not Deleted Successfully",
          showSnackbar: true,
          operationIsSuccess: false,
        });
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const duplicateRowHandler = (id: string) => {
    duplicateContent(id)
      .then((response) => {
        if (response.status === 200) {
          setSnackbarState({
            message: "Content Duplicated Successfully",
            showSnackbar: true,
            operationIsSuccess: true,
          });
          let newContentList: ContentListType[] = [];
          newContentList.push({
            description: response.data.description,
            isHomepage: response.data.isHomepage,
            id: response.data.id,
            languages: response.data.languages,
            slug: response.data.slug,
            status: "DF",
            syncTranslations: response.data.syncTranslations,
            title: response.data.title,
            type: response.data.type,
            updatedAt: response.data.updatedAt,
            statusType: response.data.statusType,
            translations: [],
            originalPage: response.data.originalPage,
            enableAddTranslation: response.data.enableAddTranslation,
            isTranslated: response.data.isTranslated,
            isUsedMessage: response.data.isUsedMessage,
            isCountrySpecific: response.data.isCountrySpecific,
            postType: response.data.postType,
          });
          newContentList = newContentList.concat(shownContentList);
          setShownContentList(newContentList);
          setContentList(newContentList);
        }
      })
      .catch(() => {
        setSnackbarState({
          message: "Content not Duplicated Successfully",
          showSnackbar: true,
          operationIsSuccess: false,
        });
      });
  };

  const setAsHomePageHandler = (id: string, status: boolean) => {
    const last_id = shownContentList.findIndex((page) => page.isHomepage === true);
    setAsHomePage(id, status).then((res) => {
      if (res.status === 200) {
        const newPageList = [...shownContentList];
        const page_id = newPageList.findIndex((page) => page.id === id);
        newPageList[page_id].isHomepage = status;
        newPageList[last_id].isHomepage = false;
        setShownContentList(newPageList);
        setSnackbarState({
          message: status ? "Page Seted As HomePage Successfully" : "Page Unseted As HomePage Successfully",
          showSnackbar: true,
          operationIsSuccess: true,
        });
      }
    });
  };

  return (
    <PageContainer>
      <Snackbar
        open={snackbarState.showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${snackbarState.operationIsSuccess ? "#03A678" : "#D11C1C"} `,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbarState({ ...snackbarState, showSnackbar: false });
        }}
      >
        <SnackbarContent message={snackbarState.message} />
      </Snackbar>
      <Title button={<AddContentButton />} title="Content" className="px-12 header-title-space" />
      <FilterBox
        applyButtonHandler={(input) => {
          setLoading(true);
          setFilterInput(input);
        }}
        languages={languages}
        loading={loading}
        searchHandler={(input) => setSearchInput(input)}
      />
      <div className="w-full bg-secondary-100  h-[8px] mt-6" />
      <div className="mt-6 px-12">
        <Table
          data={shownContentList}
          columns={columns}
          deleteRow={(id) => {
            const selectedRow = contentList.filter((list) => list.id === id);

            if (selectedRow[0].isUsedMessage) {
              setWarningMessage(selectedRow[0].isUsedMessage);
              setHasDeletePermission(false);
            } else if (selectedRow[0].isTranslated) {
              setWarningMessage("This page has related translations, please delete the translations first.");
              setHasDeletePermission(false);
            } else {
              setWarningMessage("Are you sure you want to delete this content?");
              setHasDeletePermission(true);
            }
            setSelectedRow(id);
            setShowModal(true);
          }}
          duplicateRow={duplicateRowHandler}
          setAsHomePage={setAsHomePageHandler}
        />
      </div>
      <AreYouSureModal
        description={warningMessage}
        onPositiveClicked={() => {
          hasDeletePermission ? deleteRowHandler(selectedRow!) : setShowModal(false);
        }}
        positiveButtonTitle={hasDeletePermission ? "Delete" : "Cancel"}
        showModal={showModal}
        title=""
        negativeButtonTitle={hasDeletePermission ? "Cancel" : ""}
        onNegativeClicked={() => setShowModal(false)}
        loading={deleteLoading}
      />
    </PageContainer>
  );
};

export default ContentList;
