import React, { useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PostTypeType, PostTypeOptionType, PostTypeAdditionalContent } from "./ManagePostType";
import TableRow from "./TableRow";
import Pagination from "./Pagination";

export type Column = {
  Header: string;
  accessor: any;
};

//////////////////////////////////////////////
const Table: React.FC<{
  columns: Column[];
  data: PostTypeType[];
  onOptionClick: (dictValue: PostTypeType & PostTypeAdditionalContent, selectedValue: PostTypeOptionType, id?: number) => void;
}> = ({ columns, data, onOptionClick }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    page, // Instead of using 'rows', we'll use page,
    state,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );
  // @ts-ignore

  const [rowId, setRowId] = useState(0);

  return (
    <>
      {/* table */}
      <div className="relative z-0">
        <div>
          <table {...getTableProps()} className="border-collapse w-full  ">
            <thead className="bg-secondary-200  rounded  overflow-hidden">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200 ">
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      key={index}
                      scope="col"
                      className={`border-b td-firefox border-secondary-200 table__cell sticky top-0 z-10 text-sm font-normal  text-gray py-[12.3px]  tracking-wider px-4 bg-secondary-200 ${
                        index === 0 ? "left-0 z-20 " : "left-0 z-10"
                      }`}
                    >
                      {column.render("Header") !== " " && (
                        <div className="flex w-fit items-center">
                          <h3 className="text-sm font-bold text-black41">{column.render("Header")}</h3>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                // @ts-ignore
                page.map((row, i) => (
                  <TableRow row={row} key={i} onOptionClick={onOptionClick} />
                ))
              }
            </tbody>
          </table>

          <div className="mb-4  w-full">
            <Pagination
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              setPageSize={setPageSize}
              gotoPage={gotoPage}
              pageCount={pageCount}
              // @ts-ignore
              pageIndex={state.pageIndex}
              // @ts-ignore
              pageSize={state.pageSize}
              pageLength={page.length}
              totalLength={data.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
