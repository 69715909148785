import React from "react";
import MenuItem from "../../../MenuItem";
import { PostTypeAdditionalContent, PostTypeOptionType, PostTypeType } from "./ManagePostType";
import { Row } from "react-table";

const OptionMenu: React.FC<{
  showOptionMenu: boolean;
  onOptionClick: (selectedItem: PostTypeOptionType, id?: number) => void;
  row: Row<PostTypeType & PostTypeAdditionalContent>;
}> = ({ showOptionMenu, onOptionClick, row }) => {
  return (
    <div className="relative">
      <div className={`absolute z-10 ${showOptionMenu ? "block" : "hidden"} right-[5%]`}>
        <ul className="w-36 rounded shadow bg-white py-1">
          <MenuItem className="py-2" titleKey="Edit" onClick={() => onOptionClick(PostTypeOptionType.Edit)} />
          <MenuItem className="py-2" titleKey="Delete" onClick={() => onOptionClick(PostTypeOptionType.Delete)} />
          <MenuItem
            className="py-2"
            titleKey="Manage Additional Fields"
            onClick={() => onOptionClick(PostTypeOptionType.ManageAdditionalFields)}
          />
          <MenuItem className="py-2" titleKey="Manage Categories" onClick={() => onOptionClick(PostTypeOptionType.ManageCategory)} />
          <MenuItem
            className="py-2"
            titleKey="Manage Additional Content"
            onClick={() => onOptionClick(PostTypeOptionType.AdditionalConent)}
            subMenu={[
              {
                onClick: () => onOptionClick(PostTypeOptionType.AdditionalConent, row.original.postAdditionalId),
                title: "Manage Right AC",
              },
              {
                onClick: () => onOptionClick(PostTypeOptionType.AdditionalConent, row.original.buttomPostAdditionalId),
                title: "Manage Bottom AC",
              },
            ]}
          />
        </ul>
      </div>
    </div>
  );
};

export default OptionMenu;
