import React, { useEffect, useState } from "react";
import Button, { ButtonType } from "../Button";
import Loading, { LoadingMode } from "../Loading";
import { useLocation } from "react-router-dom";
import { logoSelector } from "../../utils/utils";

const FormContainer: React.FC<{
  children: React.ReactNode;
  loading: boolean;
  submitHandler: () => void;
  buttonText: string;
  disableButton: boolean;
}> = ({ children, loading, submitHandler, buttonText, disableButton }) => {
  const [imageSrc, setImageSrc] = useState("");
  const location = useLocation();

  useEffect(() => {
    setImageSrc(logoSelector(location));
  }, []);

  return (
    <div className="bg-white pt-14  border border-secondary-300 max-w-[513px] rounded-lg shadow h-[695px] flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <img src={imageSrc} alt="logo" height={44} width={208}></img>
        </div>
        <div className="px-16">
          {children}
          <Button className="mt-8 h-[45px] w-full" onClick={submitHandler} type={ButtonType.PRIMARY} disable={disableButton}>
            {loading ? <Loading loadingMode={LoadingMode.Button} /> : buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
