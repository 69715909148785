import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import "./App.css";
import Test from "./Test";
import Login from "./components/loginAndForgetPassword/Login";
import RequiredAuth from "./utils/RequiredAuth";
import Footer from "./components/content/Footer";
import Menu from "./components/content/content_list/menu/Menu";
import ManageBlockDetailContextProvider from "./store/ManageBlockErrorContext";
import ManageBlock, { loader as manageBlockLoader, headerLoader, cookieLoader } from "./components/content/content_list/ManageBlock";
import ContentPreview, { loader as contentPreviewLoader } from "./components/content/ContentPreview";
import ManageBlocks from "./components/manage_content/manage_block/ManageBlocks";
import ContentList from "./components/content/content_list/ContentList";
import FileManager from "./components/fileManager/FileManager";
import History from "./components/History";
import ManageContent from "./components/manage_content/ManageContent";
import SiteTheme from "./components/site_theme/SiteTheme";
import SiteSetting from "./components/siteSetting/SiteSetting";
import NotFound from "./components/content/404";
import BlogSetting from "./components/BlogSetting";
import MainPage from "./components/content/MainPage";
import DataDictionaryHub from "./components/siteSetting/data_dictionary/DataDictionaryHub";
import ManageValueAndPostCategory from "./components/siteSetting/data_dictionary/manageValue/ManageValueAndPostCategory";
import ForgotPassword from "./components/loginAndForgetPassword/ForgotPassword";
import ChangePassword from "./components/loginAndForgetPassword/ChangePassword";
import Language from "./components/siteSetting/language/Language";
import PostCategory from "./components/siteSetting/PostCategory";
import PostType from "./components/siteSetting/PostType";
import PostTypeFields from "./components/siteSetting/PostTypeField";
import EditFile from "./components/fileManager/edit/EditFile";
import FaviconManager from "./FaviconManager";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<RequiredAuth />}>
        <Route path="" element={<MainPage />} />
        <Route path="content">
          <Route index element={<ContentList />} />
          <Route path=":contentId">
            <Route index element={<ManageContent />} />
            <Route path="preview" element={<ContentPreview />} loader={contentPreviewLoader} />
            <Route path="blocks">
              <Route index element={<ManageBlocks />} />
              <Route
                path=":blockId"
                element={
                  <ManageBlockDetailContextProvider>
                    <ManageBlock />
                  </ManageBlockDetailContextProvider>
                }
                loader={manageBlockLoader}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="file-manager">
        <Route index element={<FileManager />} />
        <Route path=":fileId" element={<EditFile />} />
      </Route>
      <Route path="history" element={<History />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:uid/:hashToken" element={<ChangePassword />} />
      <Route path="test" element={<Test />} />
      <Route path="site-theme" element={<SiteTheme />} />
      <Route path="language" element={<Language />} />
      <Route path="post-category" element={<PostCategory />} />
      <Route path="post-type" element={<PostType />} />
      <Route path="post-type/:postTypeId/fields" element={<PostTypeFields />} />
      <Route path="post-type/:postTypeId/categories" element={<PostCategory />} />

      <Route path="site-setting" element={<SiteSetting />} />
      <Route path="data-dictionary">
        <Route index element={<DataDictionaryHub />} />
        <Route path=":dataDictionaryId" element={<ManageValueAndPostCategory />} />
      </Route>
      <Route path="menu" element={<Menu />} />
      <Route
        path="header"
        element={
          <ManageBlockDetailContextProvider>
            <ManageBlock />
          </ManageBlockDetailContextProvider>
        }
        loader={headerLoader}
      />
      <Route
        path="cookie"
        element={
          <ManageBlockDetailContextProvider>
            <ManageBlock />
          </ManageBlockDetailContextProvider>
        }
        loader={cookieLoader}
      />
      <Route path="footer" element={<Footer />} />
      <Route path="blogSetting" element={<BlogSetting />} />
      <Route path="404" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <FaviconManager />
    </>
  );
}

export default App;
