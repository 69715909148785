import Moment from "react-moment";
import { faPalette } from "@fortawesome/pro-regular-svg-icons";
import BlockDetailsSectionContainer from "../layout/BlockDetailsSectionContainer";

const BlockDetailsMetaData: React.FC<{
  title: string;
  type: string;
  lastUpdate: string;
  onIconClick: () => void;
}> = ({ title, type, lastUpdate, onIconClick }) => {
  return (
    <BlockDetailsSectionContainer title={type} headerActions={[{ icon: faPalette, onClick: onIconClick }]}>
      <div className="flex items-center justify-between flex-wrap gap-2 font-light text-black2B">
        <p>
          <strong className="mr-1">Title:</strong>
          {title}
        </p>
        <p>
          <strong className="mr-1">Type:</strong>
          {type}
        </p>
        <p>
          <strong className="mr-1">Last Update:</strong>
          {lastUpdate !== "-" ? <Moment date={lastUpdate} format="DD.MM.YYYY" /> : "-"}
        </p>
      </div>
    </BlockDetailsSectionContainer>
  );
};

export default BlockDetailsMetaData;
