import { ImageValueType } from "../utils/types";

export const enum StatusBoxType {
  AL = "AL", // All
  DF = "DF", // Draft
  PB = "PB", // Publish
  DP = "DP", // Drafted Publish
}

export const enum FileType {
  AL = "all",
  PNG = "png",
  JPG = "jpg",
  PDF = "pdf",
  DOCS = "docs",
  SVG = "svg",
  JPEG = "jpeg",
  MP4 = "mp4",
}
export type FileDataType = {
  defaultLanguage: string;
  isUsed: string;
  fileName: string;
  type: string;
  uploadDate: string;
  numberOfUsage: number;
  size: string;
  url: string;
  languages: string[];
  alternative: {};
};
export type DropdownChoice = {
  key: string;
  label: string;
};
export type BorderStyle = "NN" | "DT" | "DS" | "SL";
export type BorderWidth = "TN" | "MD" | "TK";
export type ColorPattern = "HZ" | "VC" | "PL";
export type Direction = "TB" | "LR";

export type LanguageList = {
  language: string;
  code: string;
  active: boolean;
  default: boolean;
};

export type ContentListType = {
  title: string;
  slug: string;
  status: string;
  description: string;
  isHomepage: boolean;
  type: string;
  updatedAt: string;
  syncTranslations: boolean;
  id: string;
  languages: string[];
  statusType: string;
  translations: ContentListType[];
  originalPage: null | string;
  enableAddTranslation: boolean;
  isUsedMessage: string | null;
  isTranslated: boolean;
  isCountrySpecific: boolean;
  postType: null | string;
};

export type TreeModel = {
  // ---------- Used in Block object
  id: number;
  name: string;
  child: Array<TreeModel>;
  type?: string;
  hasTextSize?: false;
  isUsedMessage?: string | null;
  // -------------- Used in Menu item object
  url?: string;
  title?: { [key: string]: string } | null;
  parent?: number | null;
  content?: string | null;
  header?: number | null;
  scrollBlock?: string | null;
};

export type HeaderLinkType = {
  linkName: string;
  linkAddress: string;
  hasBorder: boolean;
  subMenu?: { linkName: string; linkAddress: string }[];
};

export type ThemeMenuType = {
  title: string;
  isClicked: boolean | undefined;
};
export type BorderRadius = {
  id: string;
  bottomLeft: string;
  bottomRight: string;
  topLeft: string;
  topRight: string;
};
export type BorderStyleType = {
  id: string;
  borderStyle: BorderStyle;
  borderColor: string;
  borderRadius: BorderRadius;
  borderSideArray: string[];
  borderWidth: BorderWidth;
};

export type TableStyleType = {
  id: string;
  border: BorderStyleType;
};

export type ShadowType = {
  id: string;
  x: string | null;
  y: string | null;
  blur: string | null;
};

export interface ButtonStyleType {
  border: BorderStyleType;
  shadow: ShadowType;
  background: {
    id: number;
    color: string;
    image: null | string;
    imageMobile: null | string;
    imageWidescreen: null | string;
  };
  padding: Padding;
  gradientBackground: {
    color1: string;
    color2: string;
    opacity1: string;
    opacity2: string;
  };
}

export type Padding = {
  id: string;
  bottom: string;
  left: string;
  right: string;
  top: string;
};

export type BackGroundTextBorderType = {
  id: string;
  backgroundColor: string;
  border: BorderStyleType;
  textColor: string;
};

export type FormStyleType = {
  placeholderColor: string;
  id: string;
  backgroundColor: string;
  border: BorderStyleType;
  disable: BackGroundTextBorderType;
  error: BackGroundTextBorderType;
  extraLabel: BackGroundTextBorderType;
  focus: BackGroundTextBorderType;
  padding: Padding;
  textColor: string;
};

export type TableStyle = {
  border: BorderStyleType;
  headerBorder: BorderStyleType;
  id: string;
  primaryBackgroundColor: string;
  headerBackgroundColor: string;
  secondaryBackgroundColor: string;
  stickyBackgroundColor: string;
  stickyBorder: BorderStyleType;
  colorPattern: ColorPattern;
};

export type BackgroundType = {
  color: string;
  id: string;
};

export type GradientBackgroundType = {
  color1: string;
  color2: string;
  direction: Direction;
  id: string;
  opacity1: string | null;
  opacity2: string | null;
};

export type BlockType = {
  background: BackgroundType;
  border: BorderStyleType;
  gradientBackground: GradientBackgroundType;
  id: string;
  isCentralizedInMobile: boolean;
  margin: string;
  maxHeight: string | null;
  maxWidth: string | null;
  minHeight: string | null;
  padding: Padding;
  shadow: ShadowType;
  verticalOverlap: string | null;
  width: string | null;
};

export type TooltipStyle = {
  id: string;
  backgroundColor: string;
  border: BorderStyleType;
  maxWidth: string;
  padding: Padding;
  shadow: ShadowType;
  position: string;
};

export type SiteThemeType = {
  buttonStyle: ButtonStyleType;
  formStyle: FormStyleType;
  borderSideChoices: DropdownChoice[];
  borderStyleChoices: DropdownChoice[];
  borderWidthChoices: DropdownChoice[];
  colorPatternChoices: DropdownChoice[];
  directionChoices: DropdownChoice[];
  fontWeightChoices: DropdownChoice[];
  fontFamilyChoices: DropdownChoice[];
  marginChoices: DropdownChoice[];
  tableStyle: TableStyle;
  elementBorder: BorderStyleType;
  blockStyle: BlockType;
  textColor: string;
  fontWeight: string;
  fontFamily: string;
  hoverButtonStyle: ButtonStyleType;
  tooltipStyle: TooltipStyle;
  tooltipPositionChoices: DropdownChoice[];
  arrowColor: string;
  arrowBackgroundColor: string;
};

export type SiteSettingType = {
  id: string;
  logoImage: ImageValueType | null;
  logoRedirectLink: string | null;
  logoMobileImage: ImageValueType | null;
  logoSecondaryImage: ImageValueType | null;
  favicon: ImageValueType | null;
  logoSecondaryMobileImage: ImageValueType | null;
  gtTrackingId: string | null;
};

export type FileContentType = {
  id: number;
  isUsed: string;
  name: string;
  size: string;
  type: string;
  uploadDate: string;
  url: string;
  numberOfUsage: number;
  thumbnail: string;
  alternativeText: string;
};
