import React from "react";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLgAlt } from "@fortawesome/pro-regular-svg-icons";
import Moment from "react-moment";
import { ContentListType, StatusBoxType } from "../../../../types/types";
import StatusBox from "../../../inputs/StatusBox";
import TableMenu from "./TableMenu";
import TableSetting from "./TableSetting";
import { characterSpliter } from "../../../../utils/utils";

const TableRow: React.FC<{
  row: Row<ContentListType>;
  className?: string;
  deleteRow: (id: string) => void;
  duplicateRow: (id: string) => void;
  setAsHomePage: (id: string, status: boolean) => void;
}> = ({ row, className, deleteRow, duplicateRow, setAsHomePage }) => {
  return (
    <tr className={`px-4 text-black2B border border-secondary-200 border-t-0 border-r-0 border-l-0  border-b ${className} `}>
      <td className="text-sm font-light w-[170px] sticky left-0 bg-white pl-4   td-firefox table__cell">
        <span>{characterSpliter(row.original.title, 50)}</span>
        {row.original.isHomepage && (
          <span className="ml-1">
            <FontAwesomeIcon icon={faHomeLgAlt} className="text-sm" />
          </span>
        )}
      </td>
      <td className="text-sm font-light w-[150px] pl-4 td-firefox ">
        {row.original.languages.map((lang, i) => (
          <div key={i} className="uppercase inline">
            {i !== 0 && <span className="mr-1">,</span>}
            {lang}
          </div>
        ))}
      </td>
      <td className="text-sm font-light pl-4 td-firefox ">
        {characterSpliter(row.original.originalPage ? row.original.originalPage : "", 30)}
      </td>
      <td className="text-sm font-light pl-4 td-firefox ">
        {row.original.type}
        {row.original.postType && <span>-{row.original.postType}</span>}
      </td>
      <td className="text-xs font-light pl-4 td-firefox t">
        <Moment date={row.original.updatedAt} format="DD.MM.YYYY, HH:mm" />
      </td>
      <td className="py-[7.4px] pl-4 td-firefox w-[100px]">
        {(() => {
          switch (row.original.status) {
            case "DF":
              return <StatusBox type={StatusBoxType.DF} />;
            case "PB":
              return <StatusBox type={StatusBoxType.PB} />;
            case "DP":
              return <StatusBox type={StatusBoxType.DP} />;
          }
        })()}
      </td>
      <td className=" flex justify-end mt-2 td-firefox  relative bg-white">
        <TableSetting id={row.original.id} />
        <TableMenu
          deleteRow={deleteRow}
          duplicateRow={duplicateRow}
          setAsHomePage={setAsHomePage}
          id={row.original.id}
          isHomepage={row.original.isHomepage}
          status={row.original.status}
          type={row.original.type}
          parentId={row.original.originalPage}
          enableAddTranslation={row.original.enableAddTranslation}
          isCountrySpecific={row.original.isCountrySpecific}
        />
      </td>
    </tr>
  );
};

export default TableRow;
