import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { faPlus, faMinus, faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { Snackbar, SnackbarContent } from "@mui/material";

import { BlockDetailsFieldType, BlockDetailsType, ImageValueType, LangValuePairType, OptionType } from "../../utils/types";
import { getTargetField, getTargetFieldParent } from "../../utils/utils";
import InputField from "../inputs/InputField";
import Dropdown from "../inputs/Dropdown";
import ColorPicker, { isValidHexColor } from "../inputs/ColorPicker";
import Checkbox from "../inputs/Checkbox";
import FileInput from "../inputs/FileInput";
import Button, { ButtonType } from "../Button";
import MultiCheckbox from "../inputs/MultiCheckbox";

export const getInputField = (
  field: BlockDetailsFieldType,
  onChangeValue: (
    slug: string,
    value: string | number | boolean | ImageValueType | null,
    richTextLanguage?: string,
    neededTranslation?: boolean
  ) => void,
  language?: string
) => {
  switch (field.fieldType) {
    case "string":
    case "url":
    case "email":
    case "nonTranslatable":
    case "text_area":
      let _type: "T" | "NT" | "U" | "E";
      let _value;
      switch (field.fieldType) {
        case "string":
        case "text_area":
          _type = "T";
          _value = field.value ? ((field.value as LangValuePairType[]).find(({ lang }) => lang === language)!.value as string) : "";
          break;
        case "url":
          _type = "U";
          _value = field.value ? `${field.value}` : "";
          break;
        case "email":
          _type = "E";
          _value = field.value ? `${field.value}` : "";
          break;
        case "nonTranslatable":
          _type = "NT";
          _value = field.value ? `${field.value}` : "";
          break;
      }
      return (
        <InputField
          type={_type}
          value={_value}
          onChange={(value) => onChangeValue(field.slug, value, undefined, _type === "T")}
          label={field.name}
          maxLength={field.maxLength}
          required={field.required || field.fieldType === "email"}
          errorMessage={field.errorMessage}
          isTextArea={field.fieldType === "text_area"}
          rowNum={field.rowNum}
        />
      );
    case "number":
    case "float":
      return (
        <InputField
          type={field.fieldType === "float" ? "F" : "N"}
          value={field.value !== undefined && field.value !== null ? `${field.value}` : ""}
          onChange={(value) => onChangeValue(field.slug, value ?? null)}
          label={field.name}
          required={field.required}
          min={field.validations?.min}
          max={field.validations?.max}
        />
      );
    case "boolean":
      return <Checkbox name={field.name} value={field.value as boolean} onChange={(value) => onChangeValue(field.slug, value)} />;
  }
};

const getStyleField = (
  field: BlockDetailsFieldType,
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | string[] | null) => void,
  expandedComponentSlug: string,
  onComponentClick: (slug: string) => void
) => {
  let contentElements = <></>;
  switch (field.type) {
    case "input":
      contentElements = getInputField(field, onChangeValue) ?? <></>;
      break;
    case "component":
      contentElements = (
        <FieldComponent
          field={field}
          expanded={expandedComponentSlug === field.slug}
          onClick={onComponentClick}
          onChangeValue={onChangeValue}
        />
      );
      break;
    case "colorInput":
      contentElements = (
        <ColorPicker
          color={`${field.value}`}
          setColor={(color) => onChangeValue(field.slug, color)}
          label={field.name}
          hasError={!isValidHexColor(`${field.value}`)}
        />
      );
      break;
    case "image":
      contentElements = (
        <FileInput
          label={field.name}
          value={field.value ? (field.value as ImageValueType) : null}
          onFileUpload={(value) => onChangeValue(field.slug, value)}
          onTrashClick={() => onChangeValue(field.slug, null)}
        />
      );
      break;
    case "dropdown":
      contentElements = (
        <Dropdown
          options={field.values! as OptionType[]}
          onOptionSelect={(option) => onChangeValue(field.slug, option.key)}
          selectedOption={(field.values! as OptionType[]).find(({ key }) => key === field.value) ?? null}
          label={field.name}
          isSearchable={field.isSearchable}
        />
      );
      break;
    case "multiCheckbox":
      contentElements = (
        <MultiCheckbox
          label={field.name}
          slug={field.slug}
          value={field.value as string[]}
          values={field.values as OptionType[]}
          onChangeValue={(slug, newValue) => onChangeValue(slug, newValue)}
        />
      );
      break;
  }

  return contentElements;
};

const StyleFields: React.FC<{
  fields: BlockDetailsFieldType[];
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | string[] | null) => void;
}> = ({ fields, onChangeValue }) => {
  const [expandedComponentSlug, setExpandedComponentSlug] = useState("");

  const onComponentClick = (slug: string) =>
    setExpandedComponentSlug((_expandedComponentSlug) => (slug === _expandedComponentSlug ? "" : slug));

  return (
    <ul className="grid grid-cols-1 gap-6">
      {fields.map((field, index) => (
        <li key={index}>{getStyleField(field, onChangeValue, expandedComponentSlug, onComponentClick)}</li>
      ))}
    </ul>
  );
};

const BlockTreeItem: React.FC<{
  label: string;
  item: BlockDetailsFieldType;
  index: number;
}> = ({ label, item, index }) => {
  const [open, setOpen] = useState(true);

  const itemListField = item.fields?.find(({ type }) => type === "itemList");

  return (
    <Draggable draggableId={item.slug} index={index}>
      {(provided) => (
        <li className="grid grid-cols-1 gap-3" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faGripDotsVertical} className="text-primary text-xl cursor-pointer" />
            {itemListField && (
              <div
                className="w-4 h-4 bg-primary text-white rounded-full grid place-items-center text-[10px] cursor-pointer ml-3"
                onClick={() => setOpen((_open) => !_open)}
              >
                <FontAwesomeIcon icon={open ? faMinus : faPlus} />
              </div>
            )}
            <p className="ml-3 text-sm font-light text-black2B">{label}</p>
          </div>
          {itemListField && open && (
            <div className="ml-6">
              <BlockTreeList itemListField={itemListField} droppableType="2" />
            </div>
          )}
        </li>
      )}
    </Draggable>
  );
};

const BlockTreeList: React.FC<{ itemListField: BlockDetailsFieldType; droppableType: string }> = ({ itemListField, droppableType }) => {
  const getLabel = (item: BlockDetailsFieldType) => {
    const itemTagField = item.fields?.find((item) => item.type === "itemTag");
    if (itemTagField?.value) {
      return itemTagField.value as string;
    } else {
      return `${itemListField.name} ${item.slug.substring(item.slug.lastIndexOf(".") + 1, item.slug.length)}`;
    }
  };

  return (
    <Droppable droppableId={itemListField.slug} type={droppableType}>
      {(provided) => (
        <ul className="grid grid-cols-1 gap-4" {...provided.droppableProps} ref={provided.innerRef}>
          {itemListField.fields!.map((item, index) => (
            <BlockTreeItem key={item.slug} label={getLabel(item)} item={item} index={index} />
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

const StylesSidebar: React.FC<{
  firstTabStyleFields: BlockDetailsFieldType[];
  secondTabStyleFields?: BlockDetailsFieldType[];
  thirdTabStyleFields?: BlockDetailsFieldType[];
  setFirstTabStyleFields: React.Dispatch<React.SetStateAction<{ name: string; fields: BlockDetailsFieldType[] }>>;
  tabStyleFieldsChangeHandler?: (fields: BlockDetailsFieldType[], index: number) => void;
  responsiveSidebarOpen?: boolean;
  setResponsiveSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveButtonClick: () => void;
  onCancelButtonClick: () => void;
  firstTabTitle: string;
  secondTabTitle?: string;
  thirdTabTitle?: string;
  buttonsInside?: boolean;
  hasFooter?: boolean;
  saveButtonDisable?: boolean;
  itemListField?: BlockDetailsFieldType;
  orderableItem?: BlockDetailsFieldType;
  setBlockDetails?: React.Dispatch<React.SetStateAction<BlockDetailsType>>;
  loading?: boolean;
}> = ({
  firstTabStyleFields,
  secondTabStyleFields,
  thirdTabStyleFields,
  setFirstTabStyleFields,
  tabStyleFieldsChangeHandler,
  responsiveSidebarOpen,
  setResponsiveSidebarOpen,
  onSaveButtonClick,
  onCancelButtonClick,
  firstTabTitle,
  secondTabTitle,
  thirdTabTitle,
  buttonsInside,
  hasFooter,
  saveButtonDisable,
  itemListField,
  orderableItem,
  setBlockDetails,
  loading,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [snackbar, setSnackbar] = useState<{ show: boolean; message?: string }>({ show: false });

  const mainContainer = useRef<HTMLDivElement>(null);
  const footer = useRef<HTMLElement>(null);

  useEffect(() => {
    if (mainContainer.current && footer.current) {
      mainContainer.current.style.height = `calc(100% - ${footer.current.clientHeight}px)`;
    }
  }, [footer.current?.clientHeight]);

  useEffect(() => setActiveTabIndex(0), [firstTabStyleFields]);

  const onChangeValue = (slug: string, value: string | number | boolean | ImageValueType | string[] | null) => {
    let newStyleFields = [...firstTabStyleFields];
    let targetField = getTargetField(newStyleFields, slug);
    if (targetField) {
      targetField.value = value;
      setFirstTabStyleFields(({ name }) => ({ name, fields: newStyleFields }));
      return;
    }

    if (!secondTabStyleFields || !thirdTabStyleFields || !tabStyleFieldsChangeHandler) return;

    newStyleFields = [...secondTabStyleFields];
    targetField = getTargetField(newStyleFields, slug);
    if (targetField) {
      targetField.value = value;
      tabStyleFieldsChangeHandler(newStyleFields, 0);
      return;
    }

    newStyleFields = [...thirdTabStyleFields];
    targetField = getTargetField(newStyleFields, slug);
    if (targetField) {
      targetField.value = value;
      tabStyleFieldsChangeHandler(newStyleFields, 1);
    }
  };

  const renderFirstTab = () => (
    <StyleFields fields={firstTabStyleFields.filter(({ excluded }) => !excluded)} onChangeValue={onChangeValue} />
  );

  const renderSecondTab = () => {
    if (!secondTabTitle) return;

    if (secondTabStyleFields)
      return <StyleFields fields={secondTabStyleFields.filter(({ excluded }) => !excluded)} onChangeValue={onChangeValue} />;

    const onDragEnd = (result: DropResult) => {
      if (!setBlockDetails) return;

      const { source, destination, draggableId } = result;
      if (!destination) return;
      if (source.droppableId === destination.droppableId && source.index === destination.index) return;

      // Doesn't change the parent!
      if (source.droppableId === destination.droppableId) {
        setBlockDetails((blockDetails) => {
          const newBlockDetails = JSON.parse(JSON.stringify(blockDetails)) as BlockDetailsType;
          const targetFieldParent = getTargetFieldParent(newBlockDetails.fields, draggableId)!;
          const [draggedField] = targetFieldParent.fields!.splice(source.index, 1);
          targetFieldParent.fields!.splice(destination.index, 0, draggedField);
          return newBlockDetails;
        });
      }
      // Change the parent
      else {
        setBlockDetails((blockDetails) => {
          const newBlockDetails = JSON.parse(JSON.stringify(blockDetails)) as BlockDetailsType;
          const targetFieldParent = getTargetFieldParent(newBlockDetails.fields, draggableId)!;

          // Do NOT let the user to drag the last item!
          if (targetFieldParent.fields?.length === 1) {
            setSnackbar({ show: true, message: "You can't move the last item!" });
            return blockDetails;
          }

          const [draggedField] = targetFieldParent.fields!.splice(source.index, 1);
          const targetParent = getTargetField(newBlockDetails.fields, destination.droppableId)!;
          targetParent.fields!.splice(destination.index, 0, draggedField);
          return newBlockDetails;
        });
      }
    };

    if (!itemListField && !orderableItem) return;

    return (
      <div className="flex flex-col gap-4">
        {itemListField && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="bg-white rounded-[5px] p-4 pr-0 flex-1">
              <BlockTreeList itemListField={itemListField} droppableType="1" />
            </div>
          </DragDropContext>
        )}
        {orderableItem && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="bg-white rounded-[5px] p-4 pr-0 flex-1">
              <BlockTreeList itemListField={orderableItem} droppableType="2" />
            </div>
          </DragDropContext>
        )}
      </div>
    );
  };

  const renderThirdTab = () => {
    if (!thirdTabTitle) return;

    if (thirdTabStyleFields)
      return <StyleFields fields={thirdTabStyleFields.filter(({ excluded }) => !excluded)} onChangeValue={onChangeValue} />;
  };

  const renderTabsLine = () =>
    secondTabTitle ? (
      <div
        className={`h-[2px] absolute bottom-[-2px] left-0 bg-primary duration-300 ${thirdTabTitle ? "w-1/3" : "w-1/2"}`}
        style={{ transform: `translateX(${activeTabIndex * 100}%)` }}
      />
    ) : null;

  const renderMainElementContent = () => {
    switch (activeTabIndex) {
      case 0:
        return renderFirstTab();
      case 1:
        return renderSecondTab();
      case 2:
        return renderThirdTab();
    }
  };

  let mainContainerStyle = {};
  let footerStyle = {};
  if (buttonsInside) {
    footerStyle = { boxShadow: "0 4px 4px -4px rgba(0, 0, 0, 0.15)" };
  } else {
    mainContainerStyle = { boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.15)" };
  }

  return (
    <div className="h-full flex flex-col duration-300">
      <div
        ref={mainContainer}
        className={`bg-secondary-100 flex flex-col rounded-tr-[5px] rounded-tl-[5px] h-full ${!buttonsInside && "rounded-[5px]"}`}
        style={mainContainerStyle}
      >
        <header className="flex items-center text-center border-b-2 border-b-secondary-200 font-light text-black2B relative">
          <button
            className={`flex-1 p-4 capitalize ${!secondTabTitle && "cursor-default"} ${activeTabIndex === 0 && "font-medium"}`}
            onClick={() => secondTabTitle && setActiveTabIndex(0)}
          >
            {firstTabTitle}
          </button>
          {secondTabTitle && (
            <button className={`flex-1 py-4 ${activeTabIndex === 1 && "font-medium"}`} onClick={() => setActiveTabIndex(1)}>
              {secondTabTitle}
            </button>
          )}
          {thirdTabTitle && (
            <button className={`flex-1 py-4 cursor-pointer ${activeTabIndex === 2 && "font-medium"}`} onClick={() => setActiveTabIndex(2)}>
              {thirdTabTitle}
            </button>
          )}
          {renderTabsLine()}
          {responsiveSidebarOpen !== undefined && (
            <button
              className={`bg-primary text-white rounded-[5px] w-5 h-5 grid place-items-center absolute bottom-0 left-0 translate-y-1/2 ${
                responsiveSidebarOpen ? "translate-x-1" : "translate-x-0"
              }`}
              onClick={() => setResponsiveSidebarOpen!((_responsiveSidebarOpen) => !_responsiveSidebarOpen)}
            >
              <FontAwesomeIcon icon={faAngleLeft} className={`duration-300 ${responsiveSidebarOpen ? "rotate-180" : "rotate-0"}`} />
            </button>
          )}
        </header>
        <main className="flex-1 overflow-y-scroll p-6">{renderMainElementContent()}</main>
      </div>
      {(hasFooter || buttonsInside) && (
        <footer
          ref={footer}
          className={`pt-4 flex justify-center items-center ${buttonsInside && "bg-secondary-100 rounded-br-[5px] rounded-bl-[5px] pb-4"}`}
          style={footerStyle}
        >
          <Button type={ButtonType.TERTIARY} onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button type={ButtonType.PRIMARY} className="ml-4" onClick={onSaveButtonClick} disable={saveButtonDisable} showLoading={loading}>
            Save
          </Button>
        </footer>
      )}
      <Snackbar
        open={snackbar.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#D11C1C",
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => setSnackbar({ show: false })}
      >
        <SnackbarContent message={snackbar.message} />
      </Snackbar>
    </div>
  );
};

const FieldComponent: React.FC<{
  field: BlockDetailsFieldType;
  expanded: boolean;
  onClick: (slug: string) => void;
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | string[] | null) => void;
}> = ({ field, expanded, onClick, onChangeValue }) => {
  const { slug, name, fields } = field;

  const [childHeight, setChildHeight] = useState(0);

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef.current && childRef.current) {
      parentRef.current.style.height = expanded ? `${childRef.current.clientHeight}px` : "0";
    }
  }, [expanded]);

  useEffect(() => {
    if (childRef.current) {
      new ResizeObserver((entries) => {
        if (parentRef.current && childRef.current) {
          setChildHeight(entries[0].contentRect.height);
        }
      }).observe(childRef.current);
    }
  }, [parentRef, childRef]);

  useEffect(() => setParentSize(), [childHeight, expanded]);

  const setParentSize = () => {
    parentRef.current!.style.height = expanded ? `${childHeight}px` : "0";
  };

  return (
    <div>
      <header className="mb-6 flex items-center justify-between cursor-pointer" onClick={() => onClick(slug)}>
        <p>{name}</p>
        <FontAwesomeIcon icon={faAngleDown} className={`text-primary duration-300 ${expanded ? "rotate-180" : "rotate-0"}`} />
      </header>
      <div ref={parentRef} className={`pl-4 duration-300 overflow-hidden ${expanded ? "overflow-visible" : "overflow-hidden"}`}>
        {fields && (
          <div ref={childRef}>
            <StyleFields fields={fields} onChangeValue={onChangeValue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StylesSidebar;
