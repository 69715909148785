import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { OptionType } from "../../utils/types";

const SimpleDropDown: React.FC<{
  className?: string;
  options: OptionType[];
  onChange: (option: OptionType) => void;
  selectedOption: OptionType | null;
  defaultOption: string;
}> = ({ className, options, selectedOption, onChange, defaultOption }) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

  return (
    <div
      tabIndex={0}
      onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
      onBlur={() => setDropDownIsOpen(false)}
      className={`w-[144px] h-[43px] hover:border-primary rounded border border-secondary-300  ${className}  flex items-center bg-white relative cursor-pointer`}
    >
      <p className={`pl-4 text-sm ${selectedOption?.label ? "" : "text-[#D2CEC6]"}`}>
        {selectedOption?.label ? selectedOption.label : defaultOption}
      </p>
      {dropDownIsOpen && (
        <ul className="absolute bg-white rounded border border-secondary-300  top-[45px] z-20 w-full">
          {options.map((option, i) => (
            <li
              onClick={() => {
                onChange(option);
              }}
              className={`pl-4 cursor-pointer hover:bg-secondary-100 h-[43px] text-sm flex items-center ${
                i === options.length - 1 ? "" : "border-b border-secondary-300"
              }`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <FontAwesomeIcon
        icon={faAngleDown}
        className={`absolute right-4 top-1/2 -mt-2 text-secondary-300 duration-300 ${dropDownIsOpen ? "rotate-180" : "rotate-0"}`}
      />
    </div>
  );
};

export default SimpleDropDown;
