import { useState } from "react";
import CmsModalMainShape from "../../CmsModalMainShape";
import InputField from "../../inputs/InputField";
import LanguageBox from "../../LanguageBox";
import { ToggleSwitch } from "../../layout/BlockDetailsSectionContainer";
import { DictPostValueType, ManageValueAndPostCategoryType } from "./manageValue/ManageValueAndPostCategory";
import { LangValuePairType, OptionType } from "../../../utils/types";
import RichTextInput from "../../inputs/RichTextInput";
import { useLocation } from "react-router-dom";

interface Props {
  modalType: ManageValueAndPostCategoryType;
  dictValue?: DictPostValueType;
  defaultLanguage: string;
  languages: Array<string>;
  postTypes?: Array<OptionType>;
  onClose: () => void;
  onSave: (dictValue: DictPostValueType) => void;
}

export default function AddOrEditValueModal(props: Props) {
  const dictValueDefault: DictPostValueType = {
    id: -1,
    isActive: true,
    isUsed: false,
    translation: [],
    value: {},
    type:
      props.modalType === ManageValueAndPostCategoryType.ManagePostCategory && props.postTypes ? (props.postTypes[0] as any) : undefined,
  };

  const [dictValue, setDictValue] = useState<DictPostValueType>(props.dictValue ?? dictValueDefault);
  const [language, setLanguage] = useState<string>(props.defaultLanguage);
  const [validationError, setValidationError] = useState<string | null>(null);

  const location = useLocation();
  const valueIsValid = () => {
    if (!dictValue.value[props.defaultLanguage]) {
      setValidationError(`Please set a value for default language (${props.defaultLanguage.toUpperCase()})`);
      return false;
    }

    return true;
  };

  const onChangeValue = (value: string) => {
    if (language === props.defaultLanguage) {
      setValidationError(null);
    }

    const newPublicStyle = { ...dictValue.publicStyle };
    newPublicStyle[language] = value;

    setDictValue((_dictValue) => ({
      ..._dictValue,
      publicStyle: newPublicStyle,
    }));
  };

  const getRichTextData = () => {
    if (dictValue.publicStyle) {
      const richTextData: LangValuePairType[] = [];
      for (const lang in dictValue.publicStyle) {
        richTextData.push({
          lang,
          value: dictValue.publicStyle[lang],
        });
      }
      return richTextData;
    }

    return [];
  };

  return (
    <CmsModalMainShape
      title={"Add/Edit Value"}
      positiveBtnTitle={"Save"}
      showModal={true}
      onNegativeBtnClick={props.onClose}
      onPositiveBtnClick={() => {
        if (valueIsValid()) props.onSave(dictValue);
      }}
      rootStyle={{ width: 640, height: `${location.pathname.includes("categories") ? "100vh" : "400px"}`, overflow: "auto" }}
    >
      <div className="flex items-start flex-col">
        <div className="flex flex-col w-full mt-6 justify-between">
          <div className="flex items-center">
            <label className="font-light text-black2B">Active</label>
            <ToggleSwitch
              className="ml-4"
              checked={dictValue.isActive}
              onClick={() => setDictValue({ ...dictValue, isActive: !dictValue.isActive })}
            />
          </div>
          <label className="text-black2B font-light mt-6">Value *</label>
        </div>
        <LanguageBox className="mt-2" languagesArr={props.languages} defaultLang={props.defaultLanguage} onChange={setLanguage} />
        {/* {props.modalType ===
          ManageValueAndPostCategoryType.ManagePostCategory &&
          props.postTypes && (
            <Dropdown
              className="w-full mt-2"
              label=""
              onOptionSelect={(selectedOption) =>
                setDictValue({
                  ...dictValue,
                  type: selectedOption as any,
                })
              }
              options={props.postTypes}
              selectedOption={
                dictValue.type
                  ? props.postTypes.find(
                      (pt) => pt.key === (dictValue?.type as any)?.id
                    )!
                  : props.postTypes[0]
              }
            />
          )} */}
      </div>

      <InputField
        label=""
        value={dictValue.value[language] ?? ""}
        type="T"
        onChange={(value) => {
          if (language === props.defaultLanguage) {
            setValidationError(null);
          }

          const newValue = dictValue.value;
          newValue[language] = value;

          setDictValue({ ...dictValue, value: newValue });
        }}
        placeholder={`Value 1`}
      />
      <label className="text-error text-sm mt-2 ml-1">{validationError}</label>
      {location.pathname.includes("categories") && (
        <RichTextInput
          key={`langauage-editor${language}`}
          data={getRichTextData()}
          setData={(data) => onChangeValue(data)}
          label=""
          language={language}
        />
      )}
    </CmsModalMainShape>
  );
}
