import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import MenuItem from "../../../MenuItem";
import { useNavigate } from "react-router-dom";
import { StatusBoxType } from "../../../../types/types";

const TableMenu: React.FC<{
  deleteRow: (id: string) => void;
  duplicateRow: (id: string) => void;
  setAsHomePage: (id: string, status: boolean) => void;
  id: string;
  isHomepage: boolean;
  status: string;
  type: string;
  parentId: string | null;
  enableAddTranslation: boolean;
  isCountrySpecific: boolean;
}> = ({ deleteRow, duplicateRow, setAsHomePage, id, isHomepage, status, type, parentId, enableAddTranslation, isCountrySpecific }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [menuIsHovered, setMenuIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div
        id="element"
        tabIndex={0}
        onBlur={() => {
          !menuIsHovered && setShowMenu(false);
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        className={`text-center hover:bg-gray-100 rounded ${showMenu && "bg-gray-200"}`}
      >
        <FontAwesomeIcon icon={faEllipsisV} className="cursor-pointer w-6 h-6 text-gray text-xs" />
      </div>
      {
        <div
          onClick={() => setMenuIsHovered(true)}
          onMouseOver={() => setMenuIsHovered(true)}
          onMouseOut={() => setMenuIsHovered(false)}
          onTouchStart={() => setMenuIsHovered(true)}
          className={`absolute top-8 right-0 min-w-[100px] min-h-[100px] bg-white border border-secondary-200 shadow z-50 rounded ${
            showMenu ? "block" : "hidden"
          }`}
        >
          <ul className="rounded shadow bg-white py-1 w-36">
            <MenuItem
              className="py-2 hover:bg-secondary-100"
              titleKey="Manage Settings"
              onClick={() => {
                setShowMenu(false);
                navigate(`${id}`, { state: { type: type, relatedContentId: parentId } });
              }}
            />

            <MenuItem
              className="py-2 hover:bg-secondary-100"
              titleKey={"Duplicate"}
              onClick={() => {
                duplicateRow(id);
                setShowMenu(false);
              }}
            />
            <MenuItem
              className="py-2 hover:bg-secondary-100"
              onClick={() => {
                deleteRow(id);
                setShowMenu(false);
              }}
              titleKey="Delete"
            />

            {(status === StatusBoxType.PB || status === StatusBoxType.DP) && type === "Page" && (
              <MenuItem
                className="py-2 hover:bg-secondary-100"
                onClick={() => {
                  isHomepage ? setAsHomePage(id, false) : setAsHomePage(id, true);
                  setShowMenu(false);
                }}
                titleKey={isHomepage ? "Unset As HomePage" : "Set As HomePage"}
              />
            )}
            {!parentId && !isCountrySpecific && (
              <MenuItem
                className="py-2 hover:bg-secondary-100"
                onClick={() => {
                  enableAddTranslation && navigate(`/content/${0}/`, { state: { type: type, relatedContentId: id } });
                }}
                titleKey="Add Translation"
                disable={!enableAddTranslation}
                onBlur={() => setShowMenu(false)}
              />
            )}
          </ul>
        </div>
      }
    </div>
  );
};

export default TableMenu;
