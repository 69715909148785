import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import Row from "./Row";

export type Option = { value: string; label: string };

const MultiCheckBoxLanguages: React.FC<{
  languages: Option[];
  onChange: (input: { lang: string; status: boolean }) => void;
  selectedLanguages: string[];
  defaultLanguage?: Option;
  children?: React.ReactNode;
  className?: string;
  label?: string;
}> = ({ languages, onChange, selectedLanguages, children, className, defaultLanguage, label }) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [dropDownIsHovered, setDropDownIsHovered] = useState(false);
  const [dropDownIsShown, setDropDownIsShown] = useState(false);

  let newLanguages = [defaultLanguage];
  if (defaultLanguage) {
    newLanguages = [defaultLanguage];
    newLanguages = newLanguages.concat(languages);
  } else {
    newLanguages = [...languages];
  }

  useEffect(() => {
    dropDownIsOpen && setDropDownIsShown(true);
    !dropDownIsOpen && !dropDownIsHovered && setDropDownIsShown(false);
  }, [dropDownIsHovered, dropDownIsOpen]);

  const languagesArray = languages.map((lang, i) => {
    return (
      <>
        <Row key={i} hasBorder={i !== languages.length - 1} lang={lang} onChange={onChange} selectedLanguages={selectedLanguages} />
      </>
    );
  });

  return (
    <div className="flex w-full">
      <div className={`relative w-full ${className}`}>
        <label className="text-sm font-light text-black2B">{label}</label>
        <div
          id="dropDown"
          tabIndex={0}
          onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
          onBlur={() => {
            setDropDownIsOpen(false);
          }}
          className={`relative w-full px-4 py-[9px] rounded-[5px] border mt-2 border-secondary-300 cursor-pointer bg-white h-[43px] outline-none hover:border-primary text-black caret-transparent`}
        >
          {selectedLanguages.map((lang, i) => (
            <span key={i}>
              {i !== 0 && <span>,</span>}
              {lang !== "all" && <span>{newLanguages.find((input) => input!.value === lang)?.label}</span>}
            </span>
          ))}
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`absolute right-4 text-secondary-300 duration-300 top-1/2 -translate-y-1/2 ${
              dropDownIsShown ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {dropDownIsShown && (
          <div
            className="absolute w-full   left-0 border border-secondary-300 bg-white translate-y-1 rounded-[5px] z-50"
            onMouseOver={() => {
              setDropDownIsHovered(true);
            }}
            onMouseOut={() => {
              setDropDownIsHovered(false);
            }}
            onTouchStart={() => setDropDownIsHovered(true)}
            onTouchEnd={() => setDropDownIsHovered(false)}
          >
            <ul>
              {children}
              {languagesArray}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiCheckBoxLanguages;
